@import '../../scss/mixins';
@import '../../scss/variables';

.banner_b2b {
  background-color: $cui-black-color-600;
  padding: 97px 76px;
  width: 100%;
  @media (max-width: 920px) {
    padding: 40px 20px;
  }

  &__text_image {
    margin: 0 auto;
    max-width: 1280px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1200px) {
      align-items: center;
    }

    @media (max-width: 920px) {
      flex-direction: column;
    }

    p {
      color: $cookunity-white;
      font-size: $cui-font-base;
      font-weight: $cui-font-normal;
    }

    p.pre_title {
      letter-spacing: -0.32px;
      margin-bottom: 20px;
    }

    h3 {
      color: $cookunity-white;
      font-family: $cui-font-family-rossanova;
      font-size: 74px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: -2.64px;
      text-align: left;
      margin: 0;

      @media (max-width: 1200px) {
        font-size: 70px;
      }

      @media (max-width: 920px) {
        font-size: 40px;
        letter-spacing: -1.2px;
      }
    }

    p.text {
      letter-spacing: -0.16px;
      margin: 40px 0;

      @media (max-width: 920px) {
        margin: 24px 0;
      }
    }
  }

  &__col_text {
    width: 566px;

    @media (max-width: 1200px) {
      width: 48%;
    }

    @include mobile {
      width: 100%;
    }
  }

  &__col_media {
    width: 635px;
    height: 468px;

    @media (max-width: 1200px) {
      width: 50%;
      height: auto;
    }

    @media (max-width: 920px) {
      margin-top: 32px;
      width: 48%;
    }

    @include mobile {
      width: 100%;
    }

    img {
      border-radius: 16px;
      border: 6px solid $cookunity-white;
      width: 100%;
    }
  }
}
