@import '../../scss/variables';

.faq {
  max-width: 842px;
  margin: 96px auto 68px auto;
  font-family: $cui-font-family-tt-norms;

  &_title {
    font-size: $cui-font-base;
    color: $cookunity-black;
    text-align: center;
    padding: 0;
    margin-bottom: 20px;
    font-weight: 500;
  }

  &_subtitle {
    font-size: $cui-font-4xl;
    color: $cui-black-color-300;
    text-align: center;
    line-height: $cui-line-height-4xl;
    font-weight: 600;

    @media (max-width: 550px) {
      font-size: $cui-font-2xl;
    }

    > span {
      color: $cookunity-black;
    }
  }

  &__divider {
    width: 100%;
    height: 1px;
    border-top: 0.5px solid $cui-black-color-250;
    margin: 32px auto;
  }

  &__cta_wrapper {
    width: max-content;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;

    > p {
      font-size: 20px;
      line-height: 30px;
      color: $cui-black-color-350;
      margin: 24px auto;
    }

    > button {
      margin: 0 auto;
      width: fit-content;
    }
  }
  .faq_black_mode {
    background-color: $cui-black-color-800;

    h2,
    p {
      color: $cui-gray-color-100;
    }

    .faq {
      &__divider {
        display: none;
      }
    }
  }
}

.faq_black_mode {
  background-color: $cui-black-color-800;

  h2,
  p {
    color: $cui-gray-color-100;
  }

  .faq {
    &__divider {
      display: none;
    }
  }
}
