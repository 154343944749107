@import '../../scss/variables';

.news_reviews {
  max-width: 900px;
  margin: auto;

  &__container {
    width: 100%;
    max-width: 800px;
    margin: auto;
    color: $cookunity-white;
  }

  &__logos {
    max-width: 1200px;
    display: grid;
    padding: 0;
    margin: 0 auto;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 8px;
    justify-items: center;
    align-items: center;
    width: 100%;

    :nth-child(1n) {
      align-self: flex-end;
    }

    :last-child {
      align-self: center;
    }

    @media (max-width: 900px) {
      padding: 0 30px;
    }

    > li {
      list-style: none;

      &.active {
        > svg {
          path {
            fill: $cui-yellow-color-500;
          }
        }
      }

      > svg {
        min-height: 42px;
        min-width: 90px;
        max-width: 100%;
        max-height: 60px;
        width: auto;
        height: auto;
        cursor: pointer;

        path {
          fill: rgba(255, 255, 255, 0.5);
          transition: all 0.2s ease;
        }

        &:hover {
          path {
            fill: rgba(255, 255, 255, 1);
          }
        }
      }
    }
  }

  &__testimonial {
    text-align: center;
    font-weight: 400;
    font-family: $cui-font-family-rossanova;
    font-size: 64px;
    color: $cui-yellow-color-500;
    position: absolute;
    max-width: 1200px;
    width: 100%;

    @media (max-width: 900px) {
      font-size: $cui-font-5xl;
    }

    > span {
      color: $cookunity-white;
    }

    &__wrapper {
      min-height: 390px;
      position: relative;
      display: grid;
      place-items: center;
    }
  }

  &__footnote {
    font-size: $cui-font-sm;
    color: $cookunity-gray-2;
    text-align: center;

    > span {
      color: $cookunity-white;
    }
  }
}
